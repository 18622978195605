const initialState = {
    authError:null,
    loggedIn: false,
    username: 'aadityanema'
}

const authReducer = (state = initialState, action) =>{
    switch(action.type){
        case "LOGIN_ERROR":
        console.log("Login error");
            return {
                ...state,
                authError:"Login failed"
            }
        case "LOGIN_SUCCESS":
        console.log("Login success");
        return {
            ...state,
            authError: null
        }
        case "SIGNOUT_SUCCESS":
        console.log("signed out successfully");
        return state;

        case "SIGNUP_SUCCESS":
        console.log("Signup success");
        return {
            ...state,
            authError: null
        }

        case "SIGNUP_ERROR":
        console.log("Signup Error");
        return {
            ...state,
            authError: action.error.message
        }
        default:
            return state
    }
}

export default authReducer;