import React from 'react';
import Menu from './menu/menu';

class Menus extends React.Component {

    render(){
        return(
            <div>
                <Menu name="north india meal"/>
                <Menu name="keto diet"/>
            </div>
        )
    }
}

export default Menus