import React from 'react';

class MenuItem extends React.Component {

    render(){
        return(
            <p>
                {this.props.name}, qty: {this.props.qty}
            </p>
        )
    }
}

export default MenuItem