import React from 'react';
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';
import Menu from '../menus/menu/menu';

class Home extends React.Component {
    render(){
        return(
            <div>
                
                <button>Yest</button>
                <button disabled="true"><u><i>Today's menu</i></u></button>
                <button>Tmrw</button>

                <Menu name="north indian meal"/>
                
            </div>
        )
    }
}

export default Home;