import React from 'react';

class Footer extends React.Component {
    render(){
        return(
            <hr/>
        )
    }
}

export default Footer;