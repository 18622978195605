import React from 'react';

class CartItem extends React.Component {

    render(){
        return(
                <p>{this.props.name}, qty={this.props.qty}
                
                </p>
        )
    }
}

export default CartItem;