import categoryReducer from './categoryReducer';
import authReducer from './authReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    category: categoryReducer,
    auth: authReducer
});

export default rootReducer;