import React from 'react';
import Item from './item/item'
class Category extends React.Component {

    render(){
        return(
            <div>
                <p><b>{this.props.name}</b></p>
                {this.props.items && this.props.items.map(i=>{
                    return (
                        <div>
                            <Item name={i.name} qty={i.qty}/>
                        </div>
                    )
                })}
                <hr/>
            </div>
        )
    }
}

export default Category