import React from 'react';
import Dish from './dish/dish';

class Dishes extends React.Component {

    render(){
        return(
            <div>
                <Dish name="aloo gobhi"/>
                <Dish name="kashmiri aloo"/>
            </div>
        )
    }
}

export default Dishes