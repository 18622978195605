import React from 'react';
import Menu from '../menus/menu/menu';

class Schedule extends React.Component{
    render(){
        return(
            <div>
                <p><b>Tomorrow</b></p>
                boiled veg salad meal
                <hr/>    
                <p><b>Tomorrow+1</b></p>
                north indian meal
                <hr/>
                <p><b>Tomorrow+2</b></p>
                north indian meal
                <hr/>
                <p><b>Tomorrow+3</b></p>
                keto meal
                <hr/>
                <p><b>Tomorrow+4</b></p>
                high protein diet
                <hr/>
                <p><b>Tomorrow+5</b></p>
                dosa and idli meal
                <hr/>
            </div>
        );
    }
}

export default Schedule;