const initialState = {
    categories: [
        {id:1, category:"Sauces", items:[
            {name:"ketchup", qty:"1kg"},
            {name:"mayo", qty:"0.5kg"},
        ]},
        {id:1, category:"vegetables", items:[
            {name:"potato", qty:"3kg"},
            {name:"onion", qty:"1kg"},
        ]}
    ]
}

const categoryReducer = (state = initialState, action) =>{
    switch(action.type){
        case 'CREATE_CATEGORY':
            console.log("create category:", action.item);    
            return state;
        
        case 'CREATE_CATEGORY_ERROR':
            console.log("create category error", action.error);    
            return state;
        
        default: 
            return state;
        }
}

export default categoryReducer;