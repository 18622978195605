import React from 'react';

class Settings extends React.Component {

    render(){
        return(
            <div>
                <h4><u>Houses you are part of</u></h4>
                <p>Vatika J2<button>View members</button></p>
                <p>ABC Towers<button>View members</button></p>
                <h4><u>Notification settings</u></h4>
                <h5>Daily cooking</h5>
                <p><input type="checkbox"/>notify once all/atleast 1 item(s) of menu is/are cooked</p>
                <p><input type="checkbox"/>notify when each item is cooked</p>
                <h5>Shopping list</h5>
                <p><input type="checkbox"/>notify when there items pending in the shopping cart</p>

            </div>
        )
    }
}

export default Settings;