import React from 'react';
import CartItem from './cartitem/cartitem';

class Stock extends React.Component {

    render(){
        return(
            <div>
                <p>Items that you want to buy will show here</p>
                <CartItem name="ketchup" qty="1Kg bottle"/>
                <CartItem name="lemon" qty="12 pcs"/>
                <CartItem name="wheat" qty="5 Kg"/>
            </div>
        )
    }
}

export default Stock;