import React from 'react';

class Item extends React.Component {

    render(){
        return(
            <p>{this.props.name},qty={this.props.qty} <button>+1</button></p>
        )
    }
}

export default Item