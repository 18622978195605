import React from 'react';
import Category from './category/category';
import {connect} from 'react-redux'

class Categories extends React.Component {

    render(){
        return(
            <div>
                <p>Items show here</p>
                <Category/>
                <p>Auth:{this.props.auth}</p>
                <p>Items:{this.props.categories.map(c=>{
                    return <div><Category id={c.id} name={c.category} items={c.items}/></div>
                })}</p>
            </div>
        )
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        
    }
}
const mapStateToProps=(state)=>{
    return {
        auth: state.auth.username,
        categories: state.category.categories
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Categories)
//export default Items