import React from 'react';
import {Link} from 'react-router-dom';
import {Button } from 'react-bootstrap';

class Header extends React.Component {
    render(){
        return(
            <div>
                <h2>Cook-Assist</h2>
                <div>
                <Link to="/items"><Button>Items</Button></Link>
                <Link to="/menus"><Button>All Menus</Button></Link>
                <Link to="/"><Button>Home</Button></Link>
                <Link to="/cart"><Button>Cart</Button></Link>
                <Link to="/dishes"><Button>Dishes</Button></Link>
                <Link to="/schedule"><Button>Schedule</Button></Link>
                <Link to="/settings"><Button>Settings</Button></Link>
            </div>
                <hr/>
            </div>
        )
    }
}

export default Header;