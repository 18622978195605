import React from 'react';

class Dish extends React.Component {

    render(){
        return(
            <div>
                <h4><u>Name:</u> {this.props.name}</h4>

                <h4><u>Instructions</u></h4>
                <p>1. wash potato in cold water for 5 mins</p>
                <p>2. finely chop potato</p>
                <hr/>
            </div>
        )
    }
}

export default Dish