import React from 'react';
import MenuItem from '../menuitem/menuitem'

class Menu extends React.Component {

    render(){
        return(
            <div>
                <h4>{this.props.name}</h4>
                
                <MenuItem name="aloo gobhi" qty="2 ppl"/>
                <MenuItem name="palak" qty="1 ppl"/>
                <MenuItem name="chapati" qty="5 pcs"/>
                <MenuItem name="green salad" qty="1 plate"/>
                <MenuItem name="boiled egg" qty="4 pcs"/>
                <MenuItem name="bread toast" qty="6 pcs"/>
                <MenuItem name="raita boondi" qty="1 bowl"/>
                <MenuItem name="chaas" qty="0.5 litre"/>
                
                <hr/>
            </div>
        )
    }
}

export default Menu